import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "./images/homeBan3.jpg";
import { Button } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default class Events extends Component {
  state = {
    eventsList: [],
  };

  componentDidMount() {
    document.title = "Special School in Lucknow | Autism Treatment in Lucknow";
    document.description =
      "Unlock a better life with top-notch Special school in Lucknow. Trusted experts dedicated to your health and happiness. Your journey starts here.";
    fetch("https://www.rosalinchilddevelopment.com/api/index.php/home/events")
      .then((response) => response.json())
      .then((events) => {
        this.setState({ eventsList: events });
      });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
    };
    return (
      <div className="evePagCon">
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
          <h2>Events</h2>
          <Button variant="primary" size="lg" href="/book-appointment">
            Online Appoinment
          </Button>
        </div>
        <div className="container">
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 991: 3 }}>
            <Masonry>
              {this.state.eventsList.map((event) => (
                <div className="epcEve" key={event.id}>
                  <h5 className="ttlSec">{event.heading}</h5>
                  <Slider {...settings} className="">
                    {event.listImg.map((i) => (
                      <div>
                        <img
                          src={`https://www.rosalinchilddevelopment.com/resource/images/events/${i.img}`}
                          alt={event.heading}
                        />
                      </div>
                    ))}
                  </Slider>
                  <p>{event.content}</p>
                </div>
              ))}
              {/* <div className="epcEve">
                <h5 className="ttlSec">
                  On the occasion of “World Autism Awareness” day, my article has been published in
                  Hindustan Newspaper.
                </h5>
                <Slider {...settings} className="">
                  <div>
                    <img
                      src={eve1}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={eve2}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                </Slider>
                <p>
                  On the occasion of “World Autism Awareness” day, my article has been published in
                  Hindustan Newspaper.
                </p>
              </div>
              <div className="epcEve">
                <h5 className="ttlSec">
                  Positive Parenting session at “Asian Kids School”,at “Asian Kids School”, Lucknow
                  Lucknowat “Asian Kids School”, Lucknow
                </h5>
                <Slider {...settings} className="">
                  <div>
                    <img
                      src={slider1}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider2}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider3}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider4}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                </Slider>
                <p>
                  Dr. Rosalin Nath, invited for. The sessions were fruitful for both teachers as
                  well as parents. It was all about how to early identify & manage the kids and how
                  positive parenting play important role in it.
                </p>
              </div>
              <div className="epcEve">
                <h5 className="ttlSec">
                  Positive Parenting session at “Asian Kids School”, Lucknow
                </h5>
                <Slider {...settings} className="">
                  <div>
                    <img
                      src={slider1}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider2}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider3}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider4}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                </Slider>
                <p>
                  Dr. Rosalin Nath, invited for conducting sessions on Positive Parenting at Asian
                  Kids School. The sessions were fruitful for both teachers as well as parents. It
                  was all about how to early identify & manage the kids and how positive parenting
                  play important role in it.
                </p>
              </div>
              <div className="epcEve">
                <h5 className="ttlSec">
                  Positive Parenting session at “Asian Kids School”, Lucknow
                </h5>
                <Slider {...settings} className="">
                  <div>
                    <img
                      src={slider1}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider2}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider3}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider4}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                </Slider>
                <p>
                  Dr. Rosalin Nath, invited for conducting sessions on Positive Parenting at Asian
                  Kids School. The sessions were fruitful for both teachers as well as parents. It
                  was all about how to early identify & manage the kids and how positive parenting
                  play important role in it.
                </p>
              </div>
              <div className="epcEve">
                <h5 className="ttlSec">
                  Positive Parenting session at “Asian Kids School”, Lucknow
                </h5>
                <Slider {...settings} className="">
                  <div>
                    <img
                      src={slider1}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider2}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider3}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                  <div>
                    <img
                      src={slider4}
                      alt="Events - Rosalin Child Development And Rehabilitation Centre"
                    />
                  </div>
                </Slider>
                <p>
                  Dr. Rosalin Nath, invited for conducting sessions on Positive Parenting at Asian
                  Kids School. The sessions were fruitful for both teachers as well as parents. It
                  was all about how to early identify & manage the kids and how positive parenting
                  play important role in it.
                </p>
              </div> */}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    );
  }
}
