import React, { Component } from "react";
import { stringToUrl } from "./common/Library";

export default class BlogSideMenu extends Component {
  render() {
    return (
      <div className="col-lg-4">
        <h5 className="ttl">Our Recent Blogs</h5>
        <div className="pmRecBlo">
          {this.props.blogs?.map((blog) => (
            <a href={`/parental-education/${stringToUrl(blog.heading)}`}>{blog.heading}</a>
          ))}
        </div>
      </div>
    );
  }
}
