import React, { Component } from "react";
import axios from "axios";
import slider1 from "./images/homeBan3.jpg";
import { Button } from "react-bootstrap";

import video1 from "./videos/rosalin-child-development-gallery-101.MOV";
import video2 from "./videos/rosalin-child-development-gallery-102.MOV";
import video3 from "./videos/parent_feedback_10.mp4";
import video4 from "./videos/Visual_Perceptual_Skill_Training.mp4";
import video5 from "./videos/Vestibular_Integration_Therapy.mp4";
import video6 from "./videos/parent_feedback_7.mp4";
import video7 from "./videos/parent_feedback_1.mp4";
import video8 from "./videos/parent_feedback_3.mp4";
import video9 from "./videos/parent_feedback_2.mp4";
import video10 from "./videos/parent_feedback_5.mp4";
import video11 from "./videos/parent_feedback_8.mp4";

export default class VideoGallery extends Component {
  state = {
    videos: [],
  };
  componentDidMount() {
    document.title = "Cerebral palsy treatment in Lucknow | Rosalin Video Gallery";
    axios
      .get(`https://www.rosalinchilddevelopment.com/administration/home/b2c_video`)
      .then((res) => {
        const videos = res.data;
        this.setState({ videos });
      });
  }
  render() {
    return (
      <div className="phoGalPag">
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
          <h2>Video Gallery</h2>
          <Button variant="primary" size="lg" href="/book-appointment">
            Online Appoinment
          </Button>
        </div>
        <div className="container pgpVid">
          <div className="row">
            {this.state.videos.map((video) => (
              <div className="col-md-6 col-lg-4">
                <div className="youtube16">
                  <iframe
                    src={
                      "https://www.youtube.com/embed/" +
                      video?.content.split("https://youtu.be/")[1] +
                      "?controls=0"
                    }
                    title="Rosalin Child Development Centre"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            ))}
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video1} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video2} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video3} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video4} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video5} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video6} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video7} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video8} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video9} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video10} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <video controls controlsList="nodownload" oncontextmenu="return false;">
                  <source src={video11} type="video/mp4" />
                </video>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/xWYbGw12WjI"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/k7-kkckUvis"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/JBoc3w5EKfI"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/Rrt116iKcHQ"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/PH_adua9mZM"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/Z4MN5IyFR8c"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/sYROcgzeuQY"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/m9BeR6Meg6w"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/Q5V-QKvxCeI"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/k-P6IMlTeaM"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/d0fA1lZP9Ks"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="youtube16">
                <iframe
                  src="https://www.youtube.com/embed/RGOW1HBWn6w"
                  title="Rosalin Child Development Centre"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
