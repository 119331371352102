export const stringToUrl = (inputString) => {
  // Convert the string to lowercase
  const lowercasedString = inputString.toLowerCase();

  // Replace special characters with "-"
  const urlFriendlyString = lowercasedString.replace(/[^a-zA-Z0-9]+/g, "-");

  // Remove leading and trailing "-" characters
  const cleanedString = urlFriendlyString.replace(/^-+|-+$/g, "");

  return cleanedString;
};
