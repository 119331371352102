import React, { Component } from "react";
import slider1 from "./images/homeBan2.jpg";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import googleMap from "./images/googleMap.png";

export default class ContactUs extends Component {
  componentDidMount() {
    document.title = "Contact Us - Rosalin child psychologist in Lucknow";
  }
  render() {
    return (
      <div className="conUsPag">
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
          <h2>Get In Touch With Us</h2>
          <Button variant="primary" size="lg" href="/book-appointment">
            Online Appoinment
          </Button>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="cupForm">
                <h5 className="ttl">Contact Us</h5>
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Control type="text" placeholder="Enter your Name*" />
                    </div>
                    <div className="col-md-6">
                      <Form.Control type="email" placeholder="Enter email ID*" />
                    </div>
                    <div className="col-md-6">
                      <Form.Control type="Number" placeholder="Enter Mobile Number*" />
                    </div>
                    <div className="col-md-6">
                      <Form.Control type="text" placeholder="Enter Subject*" />
                    </div>
                    <div className="col-md-12">
                      <Form.Control as="textarea" rows={3} placeholder="Your Message *" />
                    </div>
                    <div className="col-12">
                      <Button variant="primary" type="submit">
                        Send Message
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-md-4">
              <h5 className="ttl">Quick Contact</h5>
              <p>If you have any questions simply use the following contact details.</p>
              <div className="cup">
                <FontAwesomeIcon icon="location-dot" /> D-1/106, Vibhav Khand, Gomti Nagar, Lucknow,
                Uttar Pradesh 226010
              </div>
              <div className="cup">
                <FontAwesomeIcon icon="mobile-screen-button" /> 8177023887, 7275504855
              </div>
              <a className="cup" href="mailto:enquiry@rosalinchilddevelopment.com">
                <FontAwesomeIcon icon="envelope" /> enquiry@rosalinchilddevelopment.com
              </a>
            </div>
          </div>
        </div>
        {/* map */}
        <div className="gooMap">
          <img src={googleMap} alt="Google Map" />
        </div>
      </div>
    );
  }
}
