import React, { Component } from "react";
import slider1 from "./images/homeBan1.jpg";
import { Button } from "react-bootstrap";
import BlogSideMenu from "./BlogSideMenu";

export default class BlogSingle extends Component {
  componentDidMount() {
    document.title = `${this.props.blog?.heading} | Parental Education - Rosalin Child Development And Rehabilitation Centre`;
  }
  render() {
    return (
      <div className="parEduPag">
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
          <h2>Parental Education</h2>
          <h5>Rosalin Child Development and Rehabilitation Centre</h5>
          <Button variant="primary" size="lg" href="/book-appointment">
            Online Appoinment
          </Button>
        </div>
        <div className="pepMain">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="pmCon pmConInn">
                  <h5 className="ttlSec">{this.props.blog?.heading}</h5>
                  <div dangerouslySetInnerHTML={{ __html: this.props.blog?.content }} />
                </div>
              </div>
              <BlogSideMenu blogs={this.props.blogs} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
