import { useEffect } from "react";
import slider1 from "./images/aboutBan.jpg";
import bgDoctor from "./images/absGreen.jpg";
import { Button } from "react-bootstrap";
import docRosalin from "./images/Rosalin-Nath.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function About() {
  useEffect(() => {
    document.title = "Special Education in Lucknow | Speech Therapy in Lucknow";
    document.description =
      "Personalized special education in Lucknow - Expert educators, inclusive environment, brighter future. Discover support for diverse needs";
  }, []);

  return (
    <div>
      {/* banner */}
      <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
        <h2>Rosalin Child Development Centre</h2>
        <Button variant="primary" size="lg" href="/book-appointment">
          Online Appoinment
        </Button>
      </div>
      {/* doc and therapy */}
      <div className="aboUs">
        <div className="container">
          <div className="row">
            <div className="col-md-12 auAbout">
              <h4 className="ttlMain">Rosalin Child Development Centre</h4>
              <p>
                Rosalin Child Development Centre Provides you multi-disciplinary team with a
                holistic approach to rehabilitation. Our dedicated team includes well qualified &
                experienced Doctors, Occupational Therapist, Physiotherapist, Speech Therapist,
                Special Educator and Clinical Psychologist. We focus on motor and cognitive
                learning, and also work to enhance new skills.
              </p>
              <p>
                Rosalin child development Centre is genesis of Dr.Rosalin Nath. She has done
                Bachelor in Occupational Therapy (BOT) from NIRTAR- Cuttack, Masters in Occupatioanl
                Therapy (MOT) from NIOH – Kolkata (Both NIOH & NIRTAR are apex institute in the
                field of disability and rehabilitation in Govt. sector). She possess 12 yrs of
                experience in the field of rehabilitation. She is expert in Sensory Integration,
                Neurodevelopmental Therapy, Group Therapy, Son-Rise program, Theory of Mind, ABA,
                Autism movement therapy & Music Therapy. She honed her skills from KMCH-Coimbatore
                for Physical Agent & Modalities, Adaptive & Assistive Devices and Hand Splinting.
                She also received specially training by Mr. Jean Pirre Maes (International Halliwick
                Association), European Bobath Tutor Association in the field of child development to
                name a few. She is also WSTP practitioner under United State Agency for
                International Development (USAID). Now successfully completed 'Certificate course on
                Autism' in association with Adephi University, New York, USA. She imparts and shares
                her skills for which she registered OTist of ACOT (Under World Federation of
                Occupational Therapy). As she gained experience with time by working with Buds to
                Blossom-Chennai and Genius lane-Lucknow, now she conducts workshops, seminars, CME”s
                to create a workforce to tackle the issues associated with Neuro-pediatric
                rehabilitation. She has done recent workshop on Autism & Sensory Integration Therapy
                At SGPGI, Lucknow.
              </p>
            </div>
          </div>
        </div>
        <div className="auDocCon" style={{ backgroundImage: `url(${bgDoctor})` }}>
          <div className="container ddtDoc">
            <div className="row">
              <div className="col-lg-4 col-xl-3">
                <img src={docRosalin} alt="Dr. Rosalin Nath" />
              </div>
              <div className="col-lg-8 col-xl-7">
                <ul className="ddList">
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Director/Founder of Rosalin Child
                    Development Centre, LKO
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Occupational Therapist, Ph.D. Scholar,
                    NIRTAR, M.O.T (NIOH-Kol)
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Certified Autism Movement Therapist,
                    Adelphi University, USA
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Certified by International Society of
                    Wheelchair Professionals
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Trained by European Bobath Tutor
                    Association (International Halliwick Association)
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Registered OTist of ACOT under World
                    Federation of Occupational Therapist (WFOT)
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xl-5">
                <div className="auVisMis">
                  <h4 className="ttlSec">Mission</h4>
                  <p>
                    Our mission is to create an organisation where everyone will give their best
                    performance to deliver superior rehabilitation facilities that improves the
                    quality of life of every patient.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-5">
                <div className="auVisMis">
                  <h4 className="ttlSec">Vision</h4>
                  <p>
                    Our vision is to provide affordable paediatric rehabilitation services in India
                    with state of art rehabilitation centre.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
