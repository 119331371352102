import React, { Component } from "react";
import axios from "axios";
import { stringToUrl } from "./common/Library";
import slider1 from "./images/homeBan1.jpg";
import { Button } from "react-bootstrap";
import BlogSideMenu from "./BlogSideMenu";

export default class ParentalEducation extends Component {
  state = {
    blogs: [],
  };
  componentDidMount() {
    document.title = "Child Psychologist in Lucknow | ABA Therapy in Lucknow";
    document.description =
      "Discover compassionate child psychology services in Lucknow! Trust our skilled psychologist to nurture your child's well-being. Book a session today!";
    axios
      .get(`https://www.rosalinchilddevelopment.com/administration/home/b2c_blogs`)
      .then((res) => {
        const blogs = res.data;
        this.setState({ blogs });
      });
  }
  removeHTMLTags = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    return doc.body.textContent || "";
  };
  render() {
    return (
      <div className="parEduPag">
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
          <h2>Parental Education</h2>
          <Button variant="primary" size="lg" href="/book-appointment">
            Online Appoinment
          </Button>
        </div>
        <div className="pepMain">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {this?.state?.blogs.map((blog) => (
                  <div className="pmCon">
                    <h5 className="ttlSec">{blog.heading}</h5>
                    <p>{this.removeHTMLTags(blog.content)}</p>
                    <a
                      href={`/parental-education/${stringToUrl(blog.heading)}`}
                      className="readMore"
                    >
                      Read More
                    </a>
                  </div>
                ))}
              </div>
              <BlogSideMenu blogs={this?.state?.blogs} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
