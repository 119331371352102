import React, { Component } from "react";
import axios from "axios";
import slider1 from "./images/aboutBan.jpg";
import gal1 from "./images/gal1.jpg";
import gal2 from "./images/gal2.jpg";
import gal3 from "./images/gal3.jpg";
import gal4 from "./images/gal4.jpg";
import gal5 from "./images/gal5.jpg";
import gal6 from "./images/gal6.jpg";
import gal7 from "./images/gal7.jpg";
import gal8 from "./images/gal8.jpg";
import gal9 from "./images/gal9.jpg";
import gal10 from "./images/rosalin-child-development-photo-gallery-1.JPG";
import gal11 from "./images/rosalin-child-development-photo-gallery-2.JPG";
import gal12 from "./images/rosalin-child-development-photo-gallery-3.JPG";
import gal13 from "./images/rosalin-child-development-photo-gallery-4.JPG";
import gal14 from "./images/rosalin-child-development-photo-gallery-5.JPG";
import gal15 from "./images/rosalin-child-development-photo-gallery-6.JPG";
import gal16 from "./images/rosalin-child-development-photo-gallery-7.JPG";
import gal17 from "./images/rosalin-child-development-photo-gallery-8.JPG";
import { Button } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default class PhotoGallery extends Component {
  state = {
    photos: [],
  };
  componentDidMount() {
    document.title = "Photo Gallery- Speech Therapy in Lucknow | Rosalin Child Development";
    axios
      .get(`https://www.rosalinchilddevelopment.com/administration/home/b2c_photos`)
      .then((res) => {
        const photos = res.data;
        this.setState({ photos });
      });
  }
  render() {
    return (
      <div className="phoGalPag">
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
          <h2>Photo Gallery</h2>
          <Button variant="primary" size="lg" href="/book-appointment">
            Online Appoinment
          </Button>
        </div>
        <div className="container">
          <ResponsiveMasonry columnsCountBreakPoints={{ 300: 1, 550: 2, 767: 3, 991: 4 }}>
            <Masonry>
              {this.state.photos.map((photo) => (
                <div className="pgpGal">
                  <img
                    src={
                      "https://rosalinchilddevelopment.com/resource/images/events/" + photo.content
                    }
                    alt="gallery"
                  />
                </div>
              ))}
              <div className="pgpGal">
                <img src={gal10} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal11} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal12} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal13} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal14} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal15} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal16} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal17} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal1} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal2} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal3} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal4} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal5} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal6} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal7} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal8} alt="gallery" />
              </div>
              <div className="pgpGal">
                <img src={gal9} alt="gallery" />
              </div>
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    );
  }
}
