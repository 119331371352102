import { useEffect, useState } from "react";
import slider1 from "./images/homeBan1.jpg";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const JobVacancies = () => {
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [position, setPosition] = useState("");

  useEffect(() => {
    document.title = "Carrers at Rosalin Child Development And Rehabilitation Centre";
  }, []);

  const validateForm = (e) => {
    console.log({ fullName, emailId, phoneNo, whatsApp, position });
    if (
      fullName.length > 3 &&
      /\S+@\S+\.\S+/.test(emailId) &&
      phoneNo.length === 10 &&
      whatsApp.length === 10 &&
      position.length !== ""
    ) {
    } else {
      alert("Please enter valid details to submit form !");
      e.preventDefault();
    }
  };

  return (
    <div className="conUsPag jobVacPag">
      {/* banner */}
      {/* <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
        <h2>Job Vacancies</h2>
        <Button variant="primary" size="lg" href="/book-appointment">
          Online Appoinment
        </Button>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="cupForm">
              <h5 className="ttl">Share Your CV</h5>
              <Form
                enctype="multipart/form-data"
                onSubmit={validateForm}
                action="https://www.rosalinchilddevelopment.com/api/index.php/home/careers"
                method="post"
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Control
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                      name="fullName"
                      type="text"
                      placeholder="Enter your Name *"
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Control
                      onChange={(e) => setEmailId(e.target.value)}
                      value={emailId}
                      name="emailId"
                      type="email"
                      placeholder="Enter email ID *"
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Control
                      onChange={(e) => setPhoneNo(e.target.value)}
                      value={phoneNo}
                      name="phoneNo"
                      type="Number"
                      placeholder="Enter Mobile Number *"
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Control
                      onChange={(e) => setWhatsApp(e.target.value)}
                      value={whatsApp}
                      name="whatsApp"
                      type="Number"
                      placeholder="Enter WhatsApp Number *"
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Control
                      name="resume"
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Select
                      name="position"
                      onChange={(e) => setPosition(e.target.value)}
                      aria-label="Default select example"
                    >
                      {position && <option>{position}</option>}
                      <option>Special Educator</option>
                      <option>Physiotherapis</option>
                      <option>Occupational Therapist</option>
                      <option>Speech Therapist</option>
                      <option>Receptionalist</option>
                      <option>Psychologist</option>
                    </Form.Select>
                  </div>
                  <div className="col-12">
                    <Button variant="primary" type="submit">
                      Submit Resume
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div className="col-lg-4">
            <h5 className="ttl">Current Openings</h5>
            <div className="jvpOpe">
              <h5>Special Educator</h5>
              <p>
                <b>No of Vacancies : 1</b>
              </p>
              <p>
                <b>Description :</b> Urgent requirement Special Educator from Lucknow
              </p>
            </div>
            <div className="jvpOpe">
              <h5>Physiotherapist</h5>
              <p>
                <b>No of Vacancies : 1</b>
              </p>
              <p>
                <b>Description :</b> Required Physiotherapist from Lucknow
              </p>
            </div>
            <div className="jvpOpe">
              <h5>Occupational Therapist</h5>
              <p>
                <b>No of Vacancies : 1</b>
              </p>
              <p>
                <b>Description :</b> Required Occupational Therapist
              </p>
            </div>
            <div className="jvpOpe">
              <h5>Speech Therapist</h5>
              <p>
                <b>No of Vacancies : 2</b>
              </p>
              <p>
                <b>Description :</b> Urgent requirement of Speech Therapist
              </p>
            </div>
            <div className="jvpOpe">
              <h5>Psychologist</h5>
              <p>
                <b>No of Vacancies : 1</b>
              </p>
              <p>
                <b>Description :</b> Urgent requirement of Psychologist
              </p>
            </div>
            <div className="jvpOpe">
              <h5>Receptionist</h5>
              <p>
                <b>No of Vacancies : 1</b>
              </p>
              <p>
                <b>Description :</b> Urgent requirement of Receptionist
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobVacancies;
