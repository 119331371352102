import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MainBanner from "./MainBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion } from "react-bootstrap";
import Testimonials from "./Testimonials";
import docRosalin from "../images/Rosalin-Nath.jpg";
import docRosalinMin from "../images/MinRosalin-Nath.jpg";
import googleMap from "../images/googleMap.png";
import video3 from "../videos/parent_feedback_10.mp4";
import video4 from "../videos/Visual_Perceptual_Skill_Training.mp4";
import video6 from "../videos/parent_feedback_7.mp4";
import video9 from "../videos/parent_feedback_2.mp4";
import video10 from "../videos/parent_feedback_5.mp4";

const ContentIndex = () => {
  const canonicalUrl = "https://www.rosalinchilddevelopment.com";
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    document.title = "Special School & Speech Therapy in Lucknow";
    document.description =
      "Rosalin Child development centre is offering speech therapy in Lucknow and behavioral therapy in Lucknow for making you stay flexible and expressing yourself in a new way";
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          name="google-site-verification"
          content="sF8jEG16KPd-hnUJipuxbVJ0M4iJtkDDym4DxoAmPdY"
        />
      </Helmet>
      <MainBanner width={width} />
      {/* doc and therapy */}
      <div className="docDetThe">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ddtDoc">
                <img src={width > 768 ? docRosalin : docRosalinMin} alt="Dr. Rosalin Nath" />
                <h5 className="ttlSec">Dr. Rosalin Nath</h5>
                <ul className="ddList">
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Director/Founder of Rosalin Child
                    Development Centre, LKO
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Occupational Therapist, Ph.D. Scholar,
                    NIRTAR, M.O.T (NIOH-Kol)
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Certified Autism Movement Therapist,
                    Adelphi University, USA
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Certified by International Society of
                    Wheelchair Professionals
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Trained by European Bobath Tutor
                    Association (International Halliwick Association)
                  </li>
                  <li>
                    <FontAwesomeIcon icon="angle-right" /> Registered OTist of ACOT under World
                    Federation of Occupational Therapist (WFOT)
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 ddtCon">
              <h4 className="ttl">Occupational Therapy/Speech Therapy & Autism</h4>
              <p>
                Currently several intervention have been develop for children with autism depends
                upon their symptom. Many professionals like Occupational therapist, Speech
                therapist, Special educator & Child psychologist have important role in improvement
                in functioning of children with autism. These interventions may reduce symptoms;
                improve cognitive ability, social communication & participation in community.
                Treatment of autism is based on symptoms like, child have major issues in behaviour,
                social skills & ADL skills then occupational therapy would be best option. If child
                having major issues in social communication & speech production then speech
                therapist can help the child, if major issues in academic skills then special
                educator have their role. In high functioning autism sometimes child psychologist
                can help the child to cope up with anxiety, depression & obsessive thoughts.
              </p>
              <p>
                Many children with autism have problem in sensory processing. Sensory processing
                disorder in autism can be addressed by sensory integration therapy. Sensory
                integration therapy is based on sensory stimulation of sensory systems in meaningful
                way. We have mainly five sense organs like vision, auditory, olfactory, tactile, and
                gustatory. Other than that, we have two more important sensory organs that is
                vestibular & proprioceptive system. Vestibular system is mainly related with our
                body balance & proprioceptive system is mainly related with body position in
                environment. In sensory processing disorder, children with autism having problem in
                processing of senses, we get from environment. Child can perceive it as very high or
                very low, in other words they are over sensitive or under sensitive to these senses.
                In case of sensory processing disorder, Occupational Therapist uses flooding or
                gradual de-sensations technique. For hyper sensitivity of any system occupational
                therapist provides gradual sensory input by means of play activity. For
                hyposensitive child high stimulating sensory inputs requires. Play is used for media
                for therapeutic activity in children with autism.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Services */}
      <div className="ourSerInd">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb1"></div>
                <h4>Occupational Therapy</h4>
                <p>
                  Occupational Therapy is a branch of health care that helps people of all ages who
                  have physical, sensory, or cognitive issues.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb2"></div>
                <h4>Speech Therapy</h4>
                <p>
                  Speech therapy will help your child to resolve the problem related to language &
                  speech like stuttering, communication and voice disorders.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb3"></div>
                <h4>Physiotherapy</h4>
                <p>
                  Physiotherapists are expert in gross motor function, postural control, any type of
                  contracture, deformity in children with special needs.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb4"></div>
                <h4>Special Education</h4>
                <p>
                  Academic performance is the key occupation of childhood, here special educator
                  performs an important role.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb5"></div>
                <h4>Child Psychologist</h4>
                <p>
                  Child Psychologist deals not only with how children grow physically, but with
                  their mental, emotional, and social development as well.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb6"></div>
                <h4>Sensory Integration Therapy (SIT)</h4>
                <p>
                  SIT is a treatment approach which is based on stimulation of tactile,
                  proprioception, vestibular, visual & auditory system in meaningful activities.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb7"></div>
                <h4>Neuro developmental Therapy (NDT)</h4>
                <p>
                  NDT is a key treatment for neuro developmental disabilities. Generally applicable
                  for children with cerebral palsy & other neuro disabilities.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icoTtlBlo">
                <div className="itbIcon itb8"></div>
                <h4>Applied Behavioural Analysis (ABA)</h4>
                <p>
                  ABA therapy is a type of therapy that focuses on improving specific behaviours,
                  such as social skills, communication, and academics skills.
                </p>
                <a href="/services">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video n conditions */}
      <div className="accConAdd">
        <div className="container">
          <div className="row">
            {width > 768 && (
              <div className="col-lg-6">
                <div className="youtube16">
                  <video controls controlsList="nodownload" oncontextmenu="return false;">
                    <source src={video4} type="video/mp4" />
                  </video>
                </div>
              </div>
            )}
            <div className="col-lg-6">
              <h4 className="ttl">
                Conditions <span>Addressed</span>
              </h4>
              <Accordion className="accTra">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Paediaric Conditions</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Children with Autism and related
                        disorders
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Sensory processing/integrative
                        disorder
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> ADHD
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Dyslexia
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Learning disability
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Cerebral palsy
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Developmental delays
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Down syndrome
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Spina Bifida
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Hydrocephalus
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> behavioral issues
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> other developmental disabilities
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Neurological Conditions</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Stroke (Hemiplegia)
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Traumatic Brain Injury (TBI)
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Spinal Cord Injury (SCI-Paralysis)
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Parkinson’s disease
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Motor neuron disease
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Multiple sclerosis
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> G.B.Syndrome (GBS)
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Cerebral Ataxia
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Orthopedic Conditions</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Low back Pain
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Knee Pain
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Neck Pain
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Shoulder Pain
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Frozen Shoulder
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Rheumatoid Arthritis
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Hand Injury
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Brachial Plexus Injury
                      </li>
                      <li>
                        <FontAwesomeIcon icon="angle-right" /> Post fracture conditions
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {/* testimonials */}
      {width > 768 && (
        <div className="tesSlick">
          <h2 className="ttlMain">
            What our <span>Clients</span> are saying
          </h2>
          <div className="container">
            <Testimonials />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="youtube16">
                  <video controls controlsList="nodownload" oncontextmenu="return false;">
                    <source src={video3} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="youtube16">
                  <video controls controlsList="nodownload" oncontextmenu="return false;">
                    <source src={video6} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="youtube16">
                  <video controls controlsList="nodownload" oncontextmenu="return false;">
                    <source src={video9} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="youtube16">
                  <video controls controlsList="nodownload" oncontextmenu="return false;">
                    <source src={video10} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* our innovation */}
      <div className="ourInno">
        <div className="container">
          <h2 className="ttlMain">Our innovative center is based on three tenets:</h2>
          <p>
            Our focus is on over all well being of child to gain their Childhood Occupation (Play &
            Academic skills), Gross motor & fine motor skills, Daily living skills & full
            participation in social activities.
          </p>
          <p>
            We try to provide natural learning environments so that child can easily replicate
            positive behavior at their home & school environment.
          </p>
          <p>
            We focus on team approach, proper communication between different professional & Parents
            are utmost important for better outcome.
          </p>
        </div>
      </div>
      {/* map */}
      {width > 768 && (
        <div className="gooMap">
          <img src={googleMap} alt="Rosalin Child Development Centre" />
        </div>
      )}
    </div>
  );
};

export default ContentIndex;
