import { useEffect } from "react";

const NoPage = () => {
  useEffect(() => {
    document.title = "404 - Page Not Found";
  }, []);
  return (
    <div style={{ margin: "auto", padding: "80px 0", textAlign: "center" }}>
      <h2>404 - Page Not Found</h2>
      <h5>Oops! The page you are looking for is not found</h5>
      <a style={{ textDecoration: "underline" }} variant="primary" size="lg" href="/">
        Go Home
      </a>
    </div>
  );
};

export default NoPage;
