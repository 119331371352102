import { useEffect } from "react";
import slider1 from "./images/homeBan3.jpg";
import { Button } from "react-bootstrap";

function Services() {
  useEffect(() => {
    document.title = "Autism Therapy in Lucknow | Autism Center in Lucknow";
    document.description =
      "Explore effective autism therapy services in Lucknow. Our dedicated team tailors support for your child's unique needs. Start the journey to a brighter future today!";
  }, []);
  return (
    <div>
      {/* banner */}
      <div className="innBanner" style={{ backgroundImage: `url(${slider1})` }}>
        <h2>Therapeutic Touch</h2>
        <Button variant="primary" size="lg" href="/book-appointment">
          Online Appoinment
        </Button>
      </div>
      <div className="serPage">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4 className="ttl">Occupational Therapy</h4>
              <p>
                Occupational Therapist does appropriate assessment, provisional diagnosis &
                treatment. They use screening tools & standardize assessment to know the underline
                causes of problems and abnormal behaviour. They helps to resolve sensory issues by
                Sensory Integration Therapy (SIT), Physical developmental issues by
                Neurodevelopmental Therapy (NDT), issues related with Activity of Daily Living (ADL)
                like toileting, feeding, tooth brushing, dressing training by ADL Training,
                Cognitive- perceptual issues by Cognitive- Perceptual Training & issues of abnormal
                behaviour & social communication by Social Skill Training. Now a day may new
                techniques are using in Occupational Therapy like Autism movement therapy, Son
                rising program, Brain gym exercises, Art & music therapy, Theory of mind Games, ABA,
                ABC, CBT, Behavioural modification technique and Therapeutic listening are common,
                which is very much useful in controlling abnormal behaviour in children with special
                needs.
              </p>
              <h4 className="ttl">Paediatric Physiotherapy</h4>
              <p>
                Paediatric Physiotherapy is effective in the management of long-term disability
                diagnosed in early childhood. In paediatric- neurological condition like cerebral
                palsy, spina bifida, down syndrome, muscular dystrophy physiotherapist plays
                important role in ambulation training with or without assistive devices like walker,
                cane, crutches, AFO, KAFO or HKAFO. Paediatric Physiotherapy improves physical
                function, quality of life, reduce disability and also improve the outcome of
                paediatric surgery. Physiotherapists are important member of paediatric
                rehabilitation team. They are expert in gross motor function, postural control, any
                type of contracture, deformity or tightness in children with special needs.
              </p>
              <h4 className="ttl">Child Psychologist:</h4>
              <p>
                Child Psychologists assess and treat children and adolescents. They help children
                cope with stresses like anxiety, depression, fear, frustration, boredom, loneliness
                &amp; anger. Their clientele may include children with a variety of developmental
                issues, from learning disabilities to sever mental illness. A partial list of
                problems treated includes autism spectrum disorder (ASD), attention deficits hyper
                activity disorder (ADHD), learning disability, obsessive compulsive disorder (OCD),
                phobias, adjustment disorder, post-traumatic stress disorder (PTSD). Child
                Psychologist deals not only with how children grow physically, but with their
                mental, emotional, and social development as well.
              </p>
              <h4 className="ttl">
                Neurodevelopmental Therapy <span>(NDT)</span>
              </h4>
              <p>
                Neurodevelopmental treatment is a hands-on treatment approach used by Occupational
                therapist &amp; physical therapist. NDT was developed to enhance the function of
                adults and children who have difficulty in controlling the movements as a result of
                neurological insult, such as cerebral palsy, downs syndrome, stroke, and head
                injury. NDT is a key treatment for neuro-developmental disabilities in children.
                Generally applicable for children with cerebral palsy &amp; other
                neuro-disabilities. NDT is based on concept of axial control, limb dissociation,
                equilibrium &amp; protective reactions.
              </p>
            </div>
            <div className="col-md-6">
              <h4 className="ttl">Speech Therapy</h4>
              <p>
                Speech therapy is a field of expertise practiced by a clinician known as a Speech
                Language Pathologist (SLP). SLP specialize in evaluation, diagnosis, and treatment
                of communication disorder (speech and language impairment), cognitive-communication
                disorder, voice disorder, and swallowing disorder. SLP also play an important role
                in the screening, diagnosis and treatment of autism spectrum disorder. Speech
                therapy will help your child to resolve the problem related to language &amp; speech
                like stuttering, articulation and phonological disorders, voice disorders,
                dysarthria, apraxia, aphasia, delayed speech &amp; language, self-talking, verbal
                &amp; nonverbal communication. We also provide advance therapy like Auditory Verbal
                Therapy (AVT) for Cochlear Implant cases.
              </p>
              <h4 className="ttl">Special Education</h4>
              <p>
                Special education, also called special needs education, the education of children
                who differ socially, mentally or emotionally &amp; physically from the average to
                such an extent that they require modification of usual school practices. Special
                education serves children with emotional, behavioural, cognitive impairments or with
                intellectual, hearing, vision, speech, or learning disability, savant syndrome, and
                orthopaedic &amp; neurological deficits. Special educators are important team member
                of paediatric rehabilitation. As academic performance is the key occupation of
                childhood, here special educator performs an important role. Many time children with
                special needs are unable to cope- up in school environment. Special educator
                facilitates academic skill &amp; other skills which requires in school setup.
              </p>
              <h4 className="ttl">
                Sensory Integration Therapy <span>(SIT)</span>
              </h4>
              <p>
                Sensory integration therapy is essentially a form of Occupational therapy, and it is
                given by specially trained occupational therapists. It involves specific sensory
                activities to help a child appropriately respond to sound, light, touch, smell,
                proprioception, taste and other sensory inputs. SIT is a key treatment approach in
                case of autism spectrum disorder. Many time children with autism &amp; ADHD facing
                problems in sensory modulation &amp; discrimination. SIT is a treatment approach
                which is based on stimulation of tactile, proprioception, vestibular, visual,
                auditory &amp; olfactory system in meaningful activities.
              </p>
              <h4 className="ttl">
                Applied Behavioural Analysis <span>(ABA)</span>
              </h4>
              <p>
                Applied behaviour analysis is a type of interpersonal therapy in which a child works
                with a practitioner one –on-one basis. The goal of ABA is to improve social skills,
                communication skills &amp; adoptive behaviour by using interventions that are based
                on theories of learning. ABA therapy is a type of behavioural therapy that focuses
                on improving specific behaviours, such as social skills, communication, reading and
                academics as well as adaptive learning skills, such as fine motor dexterity,
                hygiene, grooming. ABA therapy applies our understanding of how behaviour works on
                real situation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
